import React from "react"

import LogoAlgolia from "./logo-algolia"
import SearchInput from "./search-input"
import searchWrapperStyles from "./search-wrapper.module.css"

export default function SearchWrapper() {
  return (
    <React.Fragment>
      <div className={searchWrapperStyles.searchWrapperDefault}>
        <SearchInput />
        <LogoAlgolia />
      </div>
    </React.Fragment>
  )
}
