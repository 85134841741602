import React from "react"

export interface Props {
  className: string | undefined
  selected: number
  options: string[]
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}
/*
const DropdownButton: React.FC<Props> = ({
  className,
  selected,
  options,
  onClick,
}) => (
  <button className={className} onClick={onClick}>
    {options[selected]}
  </button>
)*/

const DropdownButton: React.FC = ({ children }) => <button>{children}</button>

export default DropdownButton
