import React from "react"

const NavigationLinks: React.FC = ({ children }) => (
  <div
    id="navigation-links"
    role="presentation"
    style={{
      border: `red solid 1px`,
      width: `100%`,
    }}
  >
    {children}
  </div>
)

export default NavigationLinks
