import React from "react"

import pageHeaderStyle from "./page-header.module.css"

const PageHeader: React.FC = ({ children }) => (
  <div
    id="page-header"
    role="presentation"
    className={pageHeaderStyle.pageHeader}
  >
    {children}
  </div>
)

export default PageHeader
