import React from "react"
import { Link } from "gatsby"

const SidebarItem = ({ linkTo, text, icon }) => (
  <li>
    <Link to={linkTo}>
      {icon}
      <span>{text}</span>
    </Link>
  </li>
)

export default SidebarItem
