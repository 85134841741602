import React from "react"
import { Link } from "gatsby"

import { Settings } from "react-feather"
import settingsLink from "./settings-link.module.css"

const SettingsLink: React.FC = () => (
  <Link className={settingsLink.settingsLink} to="/hacker-news/settings/">
    <Settings />
    <div>Settings</div>
  </Link>
)

export default SettingsLink
