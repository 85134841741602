import React from "react"
import hnPageStyles from "./hn-page.module.css"

const HNPage: React.FC = ({ children }) => (
  <div id="project" role="presentation" className={hnPageStyles.page}>
    {children}
  </div>
)

export default HNPage
