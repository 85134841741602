import React from "react"

import Article from "./article"
import articlesStyle from "./articles.module.css"

const Articles: React.FC<{ result: any }> = ({ result }) => (
  <ul id="articles" role="presentation" className={articlesStyle.base}>
    {result.hits.map(
      (story: {
        objectID: number
        title: string
        url: string
        author: string
        points: number
        num_comments: number
      }) => (
        <li key={story.objectID}>
          <Article
            title={story.title}
            url={story.url}
            author={story.author}
            points={story.points}
            num_comments={story.num_comments}
          />
        </li>
      )
    )}
  </ul>
)

export default Articles
