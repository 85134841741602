import React from "react"

const Sidebar: React.FC = ({ children }) => (
  <div
    id="sidebar-menu"
    role="presentation"
    style={{
      width: `230px`,
      border: `red solid 1px`,
      zIndex: 1,
      position: `absolute`,
    }}
  >
    {children}
  </div>
)

export default Sidebar
