import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CopyrightInfo from "../../components/copyright-info"
import SiteLinks from "../../components/site-links"

import { results } from "../../components/hacker-news/data-for-articles"

import Articles from "../../components/hacker-news/articles"
import HNPage from "../../components/hacker-news/hn-page"
import LogoHN from "../../components/hacker-news/logo-hn"
import NavigationLinks from "../../components/hacker-news/navigation-links"
import PageHeader from "../../components/hacker-news/page-header"
import Pagination from "../../components/hacker-news/pagination"
import SearchFilters from "../../components/hacker-news/search-filters"
import { Separator } from "../../components/gatsby-sidebar/docs-breadcrumb"
import SearchWrapper from "../../components/hacker-news/search-wrapper"
import SettingsLink from "../../components/hacker-news/settings-link"
import Sidebar from "../../components/hacker-news/sidebar"

import {
  Eye,
  HelpCircle,
  Home,
  TrendingUp,
  MessageCircle,
  Star,
  Volume2,
} from "react-feather"

import sidebarStyles from "../../components/hacker-news/sidebar.module.css"
// tslint:disable-next-line: prettier
import SidebarItem from "../../components/hacker-news/sidebar-item"

const Index = () => {
  const [showThumbnails, setShowThumbnails] = useState(false)
  const [siteStyle, setSiteStyle] = useState("default")
  const [dateRange, setDateRange] = useState("all")
  const [page, setPage] = useState(30)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [hasSideBar, setHasSideBar] = useState<boolean>(true)

  return (
    <Layout>
      <SEO title="Search Hacker News Project" />

      <HNPage>
        <PageHeader>
          <LogoHN />
          <SearchWrapper />
          <SettingsLink />
        </PageHeader>

        <div
          id="main"
          role="presentation"
          style={{
            width: `100%`,
            border: `red solid 1px`,
            display: `flex`,
          }}
        >
          <div
            id="content"
            role="presentation"
            style={{
              marginLeft: `230px`,
              border: `red solid 1px`,
              width: `100%`,
            }}
          >
            <SearchFilters />

            <Articles result={results} />

            <Pagination>Pagination</Pagination>

            <NavigationLinks>Navigation links</NavigationLinks>
          </div>

          <Sidebar siteStyle={siteStyle}>
            <ul className={sidebarStyles.base}>
              <SidebarItem linkTo="/hacker-news/" text="All" icon={<Home />} />
              <SidebarItem
                linkTo="/hacker-news/hot/"
                text="Hot"
                icon={<TrendingUp />}
              />
              <SidebarItem
                linkTo="/hacker-news/show-hn/"
                text="Show HN"
                icon={<Volume2 />}
              />
              <SidebarItem
                linkTo="/hacker-news/ask-hn/"
                text="Ask HN"
                icon={<MessageCircle />}
              />
              <SidebarItem
                linkTo="/hacker-news/polls/"
                text="Polls"
                icon={<HelpCircle />}
              />
              <SidebarItem
                linkTo="/hacker-news/jobs/"
                text="Jobs"
                icon={<Eye />}
              />
              <hr />
              <SidebarItem
                linkTo="/hacker-news/starred/"
                text="Starred"
                icon={<Star />}
              />
              <hr />
            </ul>

            {/*<div className="sliding-menu-fade-screen ng-scope" ng-click="toggleNav()"></div>*/}
          </Sidebar>
        </div>
      </HNPage>

      <SiteLinks>
        <Link to="/">Landing Page</Link>{" "}
        <Separator character={<span>{` `}</span>} />
        <Link to="/index-project/">Projects</Link>
      </SiteLinks>

      <CopyrightInfo />
    </Layout>
  )
}

export default Index
