import React, { useState } from "react"

import Dropdown from "./dropdown"
import DropdownButton from "./dropdown-button"
import DropdownItem from "./dropdown-item"
import searchFiltersStyle from "./search-filters.module.css"
import { Share2 } from "react-feather"

const SearchFilters = () => {
  const [selectedType, setSelectedType] = useState("Stories")
  const [selectedHow, setSelectedHow] = useState("Popularity")
  const [selectedWhen, setSelectedWhen] = useState("All time")

  return (
    <div
      id="search-filters"
      role="presentation"
      className={searchFiltersStyle.mainHeaderDefault}
      style={{
        border: `red solid 1px`,
        width: `100%`,
      }}
    >
      <span className={searchFiltersStyle.filterLabel}>Search</span>

      <Dropdown
        className={searchFiltersStyle.searchInfos}
        options={["All", "Stories", "Comments"]}
        selected={selectedType}
        onClick={() => {
          return null
        }}
      >
        Stories
      </Dropdown>
      {/*
      <DropdownButton
        className={searchFiltersStyle.searchInfos}
        selected={1}
        options={["All", "Stories", "Comments"]}
        onClick={setSelectedType(selectedType => options[selectedType].index())}
      />
            */}

      <span style={{ width: `100px` }}>by</span>

      <Dropdown
        className={searchFiltersStyle.searchInfos}
        options={["Popularity", "Date"]}
        selected={selectedHow}
        onClick={() => {
          return null
        }}
      >
        Popularity
      </Dropdown>

      <span style={{ width: `100px` }}>for</span>

      <Dropdown
        className={searchFiltersStyle.searchInfos}
        options={[
          "All time",
          "Last 24h",
          "Past Week",
          "Past Month",
          "Past Year",
          "Custom Range",
        ]}
        selected={selectedWhen}
        onClick={() => {
          return null
        }}
      >
        All Time
      </Dropdown>

      <span># result(s) (0.00N seconds)</span>

      <span>
        <Share2 />
      </span>
    </div>
  )
}

export default SearchFilters
