import React from "react"
import { Link } from "gatsby"

import logoHNStyle from "./logo-hn.module.css"

const LogoHN: React.FC = () => (
  <Link className={logoHNStyle.logo} to="/hacker-news/">
    <img
      src="https://hn.algolia.com/assets/logo-hn-search.png"
      alt="Hacker News"
    />
    <div>
      Search
      <br />
      Hacker News
    </div>
  </Link>
)

export default LogoHN
