import React from "react"

import Algolia from "../../images/logo-algolia-nebula-blue-full.svg"
import logoAlgoliaStyle from "./logo-algolia.module.css"

const LogoAlgolia: React.FC = () => (
  <span className={logoAlgoliaStyle.logo}>
    Search by
    <a
      href={
        "https://www.algolia.com/?utm_source=hn_search&utm_medium=link&utm_term=logo&utm_campaign=hn_algolia"
      }
      title={"Realtime Search Engine"}
      target="_blank"
    >
      <img
        src={Algolia}
        alt={"Algolia Logo White Full"}
        width="60"
        height="15"
      />
    </a>
  </span>
)

export default LogoAlgolia
