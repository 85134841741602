import React from "react"

import { Separator } from "../gatsby-sidebar/docs-breadcrumb"
import { makePlural } from "./utils"

export interface Props {
  title: string
  url: string
  author: string
  points: number
  num_comments: number
}

const Article: React.FC<Props> = ({
  title,
  url,
  points,
  author,
  num_comments,
}) => (
  <div role="presentation">
    <div>
      <strong>
        <a href="#" style={{ textDecoration: "none" }}>
          {title}{" "}
        </a>
      </strong>
      <span>
        <a href="#" style={{ textDecoration: "none" }}>
          ({url})
        </a>
      </span>
    </div>
    <div>
      <span>
        <a href="#" style={{ textDecoration: "none" }}>
          {points} point{makePlural(points)}
        </a>
      </span>
      <Separator character={<span>|</span>} />
      <span>
        <a href="#" style={{ textDecoration: "none" }}>
          {author}
        </a>
      </span>
      <Separator character={<span>|</span>} />
      <span>
        <a href="#" style={{ textDecoration: "none" }}>
          when
        </a>
      </span>
      <Separator character={<span>|</span>} />
      <span>
        <a href="#" style={{ textDecoration: "none" }}>
          {num_comments} comment{makePlural(num_comments)}
        </a>
      </span>
    </div>
  </div>
)

export default Article
