import React from "react"

import { Search } from "react-feather"
import searchInputStyle from "./search-input.module.css"

export default function SearchInput() {
  return (
    <div className={searchInputStyle.searchInput}>
      <Search />
      <input
        type="search"
        placeholder="Search stories by title, url or author"
        autoComplete="off"
        autoCapitalize="off"
        spellCheck="false"
        autoCorrect="off"
      />
    </div>
  )
}
