import React from "react"

const Pagination: React.FC = ({ children }) => (
  <div
    id="pagination"
    role="presentation"
    style={{
      border: `red solid 1px`,
      margin: `1.5rem 0 2.0rem`,
      width: `100%`,
    }}
  >
    {children}
  </div>
)

export default Pagination
