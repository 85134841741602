import React from "react"

import DropdownButton from "./dropdown-button"
import DropdownList from "./dropdown-list"
import dropdownButtonStyle from "./dropdown-button.module.css"

export interface Props {
  className: string
  options: string[]
  selected: string
  onClick: () => void
}
const onClick = isOpen => setIsOpen(isOpen => !isOpen)

const Dropdown: React.FC<{ className: string }> = ({ className, children }) => {
  return (
    <div>
      <DropdownButton className={className}>{children}</DropdownButton>
    </div>
  )
}

export default Dropdown
